"use client";
import GameCardHottestSkeleton from "@/components/game/card/hottest-skeleton";
import ScoreboardSkeleton from "@/components/global/scoreboard-skeleton";

export default function Loading() {
  return (
    <div className="min-h-[100vh]">
      <div className="block min-[1000px]:hidden">
        <ScoreboardSkeleton view="grid" />
      </div>
      <div className="hidden min-[1000px]:block">
        <ScoreboardSkeleton view="slider" />
      </div>
      <div className="container">
        <GameCardHottestSkeleton />
      </div>
      <div className="container pt-6">
        <div className="bg-white rounded-xl w-full animate-pulse h-52"></div>
      </div>
    </div>
  );
}
