import React from 'react';

export default function GameCardCompactSkeleton({view}: {view: "slider" | "grid"}) {
    return (
      <div className={`animate-pulse flex flex-col gap-2 bg-surface shadow-elevation-400 mr-4 p-4 rounded-xl min-w-[200px] h-[132px] ${
        view === "grid"
          ? "scale-[0.80] min-[370px]:scale-[0.825] min-[380px]:scale-[0.85] min-[390px]:scale-[0.875] min-[400px]:scale-[0.9] min-[410px]:scale-[0.925] min-[420px]:scale-[0.95] min-[440px]:scale-100"
          : undefined
      } ${
        view === "slider" ? "mr-4" : undefined
      } origin-top-left w-[200px] h-[132px] rounded-xl`}>
        <div className="flex justify-between">
          <div className="bg-surface-400 rounded-[10px] w-16 h-[14px] animate-pulse"></div>
          <div className="bg-surface-400 rounded-[10px] w-10 h-[14px] animate-pulse"></div>
        </div>
        <div className="flex justify-between gap-2">
          <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
          <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
          <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
        </div>
        <div className="flex justify-between gap-2">
          <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
          <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
          <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
        </div>
      </div>
    );
  }